jQuery(function ($) {

    var anbieter = false;
    var tags = [];

    $('.tagbutton-active').each(function () {
        tags.push(this.href.split(/\//).pop());
    })

    var refreshQueryBlock = function () {
        $('#queryselected').html('');
        $('.tagbutton-active').each(function () {
            var that = $(this);
            var button = $('<div class="button"><span>' + that.text() + '</span></div>');
            button.click(function () {
                that.click();
            });
            $('#queryselected').append(button);
        });
        $('.catbutton-active').each(function () {
            var that = $(this);
            var button = $('<div class="button"><span>' + that.text() + '</span></div>');
            button.click(function () {
                that.click();
            });
            $('#queryselected').append(button);
        });
        $('#queryselected').append($('<div></div>').css('clear', 'both'));
    };

    refreshQueryBlock();

    var doReload = function (url) {

        var myTags = false;

        var data = {};
        if (anbieter) {
            data.category_name = anbieter;
        } else {
            data.category_name = 'reset';
        }
        ;
        if (tags.length) {
            data.tag = tags.join('+');
        } else {
            data.tag = 'reset';
        }
        ;

        url = url || "/";

        $.get(
            '/setAjax.php',
            data,
            function () {
                var target = $('#content');
                target.fadeOut('fast', function () {
                    $.get(url, {}, function (responseText) {
                        rscript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
                        target.html($('<div>').html(responseText.replace(rscript, "")).find('#content'));

                        target.fadeIn('fast', function () {
                            Hyphenator.config({
                                classname: 'excerpt'
                            });
                            Hyphenator.run();
                            //pagePreparation();
                        });
                    })

                })

            }
        )

    };

    $('.navigation a').click(function (ev) {
        var href = this.href;
        ev.preventDefault();
        $.get('/setAjax.php', {category_name: 'reset', tag: 'reset'}, function () {
            window.location.href = href;
        });
    });

    $('#filter a[rel=tagfilter] ').click(function (ev) {

        var that = $(this);

        ev.preventDefault();

        var tag = this.href.split(/\//).pop();
        var arrayPosition = $.inArray(tag, tags);
        if (arrayPosition != -1) {
            // Eintrag schon vorhanden --> entfernen
            tags.splice(arrayPosition, 1);
            that.removeClass('tagbutton-active');
            that.parent().removeClass('buttonactive');
            tag = false;
        } else {
            // Eintrag nicht vorhanden --> hinzufügen
            that.addClass('tagbutton-active');
            that.parent().addClass('buttonactive');
            tags.push(tag);
        }
        ;

        refreshQueryBlock();

        doReload();
        return false;
    });

    $('#filter a[rel=catfilter]').each(function () {
        var that = $(this);
        var cat = false;
        var urlParts = this.href.split(/\//);
        while (!cat) cat = urlParts.pop();
        that.data('cat', cat);

        that.click(function (ev) {
            ev.preventDefault();

            if (that.hasClass('catbutton-active')) {
                anbieter = false;
                that.removeClass('catbutton-active');
                that.parent().removeClass('buttonactive');
            } else {
                $('#filter a[rel=catfilter]').removeClass('catbutton-active');
                that.addClass('catbutton-active');
                that.parent().addClass('buttonactive');
                anbieter = that.data('cat');
            }
            ;

            refreshQueryBlock();
            doReload();

            return false;
        });
    })

   /* $('ul#filter li ul').hide();
    $('ul#filter li').removeClass('active');
    $('ul#filter li a').removeClass('active');
    *///$('#bg-overlay').fadeToggle('fast').hide();

    widths = [40,
        87,
        134,
        181,
        228,
        275,
        322,
        369,
        416,
        463];
    var filters = 0;
    $('ul#filter li ul').each(function () {
        var rowLength = 0;
        var maxRowLength = $(this).width();
        var lastItem = false;
        $('li', this).each(function () {
            var w = $(this).width() + 7;
            for (var i = 0; i < widths.length; i++) {
                if (widths[i] >= w) {

                    $(this).width(widths[i] - 7);
                    if ((rowLength + widths[i]) > maxRowLength && lastItem) {
                        lastItem.width(maxRowLength - rowLength - 7 + lastItem.width());
                        rowLength = widths[i];
                    } else {
                        rowLength += widths[i];
                    }
                    lastItem = $(this);

                    return true;
                }
            }
        });

        $(this).data('height', $(this).height());

    })

    $('ul#filter li ul').hide().css('visibility', 'visible');

    var filter = $('ul#filter > li > div');

    filter.each(function () {

        var link = $(this).children('a');
        var submenu = $(this).parent().children('ul');

        link.data('submenu', submenu)
            .data('active', false);

        link.click(function () {
            $('ul#filter li ul').hide();
            $('ul#filter li').removeClass('active');
            $('ul#filter li a').removeClass('active');
            //$('#bg-overlay').fadeToggle('fast').hide();
            if (!$(this).data('active')) {
                $(this)
                    .data('submenu')
                    .css('height', '0px')
                    .show()
                    .animate({height: '+=' + parseInt($(this).data('submenu').data('height') + 5)}, 'fast');
                $('ul#filter li a').data('active', false);
                $(this).data('active', true);
                $(this).parent().addClass('active');
                $(this).addClass('active');
                //$('#bg-overlay').show();
            } else {
                $(this).data('active', false);
            }
        });
    });
});
